import { componentsConfig as defaultConfig } from "srs.sharedcomponents/lib/esm/components/config";
import CustomHeader from "../Header";
import CustomWelcome from "../Welcome"

const componentsConfig = {
  ...defaultConfig,
  Header: CustomHeader,
  HomePage: CustomWelcome,
};

export { componentsConfig };
